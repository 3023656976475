import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layouts/investments_layout"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { GatsbyImage } from "gatsby-plugin-image"
import Carousel from "react-bootstrap/Carousel"
import Meta from "../components/meta"


const Team = ({ data }) => (
  <Layout>
    <Meta title="threesixty Investments Ltd Team" description="Learn more about our highly experienced team." />
    <Container>
      <Row>
        <Col>
          <h1 className="display-5 mb-4">Team</h1>
        </Col>
      </Row>
      {data.allMarkdownRemark.edges.map(({ node }) => (
        <section key={node}>
          <Row className="mb-4 team-member d-none d-sm-flex">
            <Col>
              <div className="card">
                <GatsbyImage className="card-img" width="500" image={node.frontmatter.bigImage.childImageSharp.gatsbyImageData} alt={node.frontmatter.name} />
              </div>
            </Col>
            <Col>
              <h2 className="fw-normal">{node.frontmatter.name}</h2>
              <h3>{node.frontmatter.title}</h3>
              <div dangerouslySetInnerHTML={{ __html: node.html }} />
            </Col>
          </Row>
          <Row className="mb-4 team-member d-block d-sm-none">
            <Col>
              <Carousel controls={true} interval={null}>
                <Carousel.Item>
                  <div className="card">
                    <GatsbyImage className="card-img" width="500" image={node.frontmatter.bigImage.childImageSharp.gatsbyImageData} alt={node.frontmatter.name} />
                  </div>
                </Carousel.Item>
                {node.frontmatter.sideImage &&
                  <Carousel.Item>
                    <div className="card">
                      <GatsbyImage className="card-img" width="500" image={node.frontmatter.sideImage.childImageSharp.gatsbyImageData} alt={node.frontmatter.name} />
                    </div>
                  </Carousel.Item>
                }
              </Carousel>
            </Col>
            <Col>
              <h2 className="fw-normal">{node.frontmatter.name}</h2>
              <h3>{node.frontmatter.title}</h3>
              <div dangerouslySetInnerHTML={{ __html: node.html }} />
            </Col>
          </Row>
        </section>
      ))}
    </Container >
  </Layout >
)

export default Team;

export const teamImage = graphql`
  fragment teamMemberImage on File {
    childImageSharp {
      gatsbyImageData(width: 400, height: 250, quality: 90, layout: CONSTRAINED, transformOptions: {cropFocus: NORTH} )
    }
  }

  fragment teamMemberThumbnail on File {
    childImageSharp {
      gatsbyImageData(width: 500, height: 500, quality: 90, layout: CONSTRAINED, transformOptions: {cropFocus: NORTH} )
    }
  }
`

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fields: { type: { eq: "team" } } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      totalCount
      edges {
        node {
          id
          html
          frontmatter {
            name
            title
            date(formatString: "DD MMMM, YYYY")
            thumbnail {
              ...teamMemberThumbnail
            }
            bigImage {
              ...teamMemberThumbnail
            }
            sideImage {
              ...teamMemberThumbnail
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`

